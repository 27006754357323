import '@aws-amplify/ui-react/styles.css';
import {
    Datagrid,
    TextField,
    SimpleForm,
    Create,
    Show,
    SimpleShowLayout,
    DateField, TabbedForm, Toolbar, SaveButton,
    ShowButton, List, BooleanField, BooleanInput, useListContext,
    ReferenceField, FileInput, FileField,
    useStore, WrapperField, useRedirect, useNotify,
    ReferenceManyField, useRecordContext, useGetList, ListContextProvider, Pagination,
    CreateButton, FunctionField, SingleFieldList, ChipField, TextInput, TopToolbar, NumberInput, NumberField, ReferenceInput, SelectInput, Edit, EditButton, useGetOne, RichTextField, usePermissions
} from "react-admin";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, FormLabel, Button, Box, Select, MenuItem, Typography, Grid, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { RotationFilterForm } from "./RotationFilterForm";
import { RotationPreferencesList } from './Dashboard';
import { useEffect, useState } from 'react';
import { DataGridWithIndex } from './DataGridWithIndex'
import { RichTextInput } from 'ra-input-rich-text';
import { BackButton } from './BackButton';
import { is_preference_white_text, get_color_code, ordinal_suffix_of } from './variable_preferences';


export const TraineePreferencesList = ({ priority, links }) => {
    const record = useRecordContext();
    if (record.priority === priority) {
        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" link={links} sx={{ '& .MuiChip-label': { color: 'black' } }} >
                <ChipField source="name" />
            </ReferenceField>
        )
    }
}

export const ManualOverrideField = (props) => {

    const redirect = useRedirect();
    const traineeRecord = useRecordContext();

    if (traineeRecord?.manualOverrideID) {
        return (
            <ReferenceField source="manualOverrideID" reference="departments" link={(record, resource) => `/trainees/${traineeRecord.id}`} sx={{ '& .MuiChip-label': { color: 'black' } }} >
                <ChipField source="name" />
            </ReferenceField>
        )
    } else {
        return (
            <Button onClick={(e) => { e.stopPropagation(); redirect('edit', 'trainees', traineeRecord.id); }}>
                <AddIcon />
            </Button>
        )
    }
}

export const EditTickField = (props) => {

    const redirect = useRedirect();
    const traineeRecord = useRecordContext();

    return (
        <Button onClick={(e) => {
            e.stopPropagation(); redirect('edit', 'trainees', traineeRecord.id, {}, { tick: props?.tick });
        }}>
            Edit
        </Button >
    )

}

export const SeatField = (props) => {
    const record = useRecordContext();
    // console.log('seat', record)
    var priority = record['preferencePriority']
    if (priority == 0) {
        priority = parseInt(process.env.REACT_APP_NUM_OF_PREFRENCES) + 1
    }
    if (priority != null) {
        if (priority > process.env.REACT_APP_NUM_OF_PREFRENCES) {
            priority = parseInt(process.env.REACT_APP_NUM_OF_PREFRENCES) + 1
        }
        const text_color = is_preference_white_text(priority) ? 'white' : 'black'

        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" link="show" sx={{ '& .MuiChip-label': { color: 'black' } }}  >
                <ChipField source="name" sx={{ backgroundColor: get_color_code(priority), '& .MuiChip-label': { color: text_color } }} />
            </ReferenceField>
        )
    } else {
        return (
            <ReferenceField source="departmentID" reference="departments" label="Department" link="show" sx={{ '& .MuiChip-label': { color: 'black' } }} >
                <ChipField source="name" />
            </ReferenceField>
        )
    }
}

const TraineeShowPageLink = () => {
    const trainee = useRecordContext();
    return (
        <Link style={{ textDecoration: 'none', color: '#1976d2' }} to={'/trainees/' + trainee.id + '/show'}>{trainee.name}</Link>
    )
}

export const TraineeList = (props) => {

    const rotationsList = useGetList('rotations');
    useEffect(() => {
        var rotations = rotationsList.data
        rotations?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
        const currentRotation = rotations?.at(-1)
        if (currentRotation?.id != filter) {
            setFilter(currentRotation?.id)
        }
        setCurrentCohorts(rotations?.filter((rotation) => (rotation.cohortsIndex <= 4)))
    })
    const [currentCohorts, setCurrentCohorts] = useStore('trainee.currentCohorts')
    const [filter, setFilter] = useStore('trainee.filter');
    const [page, setPage] = useStore('trainee.page', 1);
    const [perPage, setPerPage] = useStore('trainee.perPage', 100);
    const sort = { field: 'name', order: 'ASC' };
    var { data, pageInfo, isLoading } = useGetList('trainees', {
        filter: { traineesByTypeAndName: { type: "Trainee", filter: { or: currentCohorts?.map((rotation) => ({ cohortID: { eq: rotation.id } })) } } },
        pagination: { page, perPage },
        sort,
    });
    // data = data?.filter((trainee) => (trainee.cohort.cohortsIndex < 3));
    if (isLoading) { return (<></>) }
    const { hasNextPage, hasPreviousPage } = pageInfo
    // const { hasNextPage, hasPreviousPage } = pageInfo;
    const filterValues = { preferencesByTraineeIDAndRotationID: { rotationID: { "eq": filter } } };
    const seatsFilterValues = { seatsByTraineeIDAndRotationID: { rotationID: { "eq": filter } } };
    const publishedSeatsFilterValues = { publishedSeatsByTraineeIDAndRotationID: { rotationID: { "eq": filter } } };
    const setFilters = filters => setFilter(filters.rotation);
    var preferenceColumns = []
    for (let priority = 0; priority < process.env.REACT_APP_NUM_OF_PREFRENCES; priority++) {

        preferenceColumns.push(<ReferenceManyField label={ordinal_suffix_of(priority + 1) + " Preferences"} reference="preferences" target="preferencesByTraineeIDAndRotationID.traineeID.rotationID" filter={filterValues}>
            <SingleFieldList >
                <TraineePreferencesList priority={priority + 1} links={false} />
            </SingleFieldList>
        </ReferenceManyField>)
    }
    return (
        <ListContextProvider value={{ data, page, perPage, setPage, setPerPage, hasNextPage, hasPreviousPage, filterValues, setFilters, sort }} >

            <RotationFilterForm  {...{ filter, setFilters, isLoading }} />
            <div>
                <Card>
                    <DataGridWithIndex sort={sort} sx={{
                        '& .column-id': { textAlign: 'left' },
                        '& .column-name': { textAlign: 'left' },
                    }} >
                        <WrapperField source="name">
                            <TraineeShowPageLink />
                        </WrapperField>
                        <ReferenceField source="cohortID" reference="rotations" label="Cohort" link={false}>
                            <FunctionField render={(record) => { if (record.cohortsIndex <= 2) { return "First year"; } else if (record.cohortsIndex <= 4) { return "Second year"; } }} />
                        </ReferenceField>
                        {preferenceColumns}
                        {/* <NewPreferenceButton rotationID={filter} /> */}
                        <ManualOverrideField {...props} label="Manual Override" />
                        <ReferenceManyField label="Assigned Seats" reference='seats' target='seatsByTraineeIDAndRotationID.traineeID.rotationID' filter={seatsFilterValues}>
                            <SingleFieldList linkType={false}>
                                <SeatField />
                            </SingleFieldList>
                        </ReferenceManyField>
                        {/* <ReferenceManyField label="Published Seats" reference='publishedSeats' target='publishedSeatsByTraineeIDAndRotationID.traineeID.rotationID' filter={publishedSeatsFilterValues}>
                            <SingleFieldList  label="Published Seats" linkType="show">
                                <SeatField />
                            </SingleFieldList>
                        </ReferenceManyField> */}
                    </DataGridWithIndex>
                </Card>
                <PostPagination />
            </div>
        </ListContextProvider>
    );
};

export const TraineeCreate = (props) => {
    const { permissions } = usePermissions();
    return (
        <Create {...props} actions={<BackButton />}>
            <TabbedForm defaultValues={{ type: "Trainee" }}>
                <TabbedForm.Tab label="single">
                    <TextInput source="email" />
                    <TextInput source="name" />
                    <ReferenceInput source="cohort" reference="rotations" />
                </TabbedForm.Tab>
                {permissions?.includes("SuperAdmins") &&
                    <TabbedForm.Tab label="batch">
                        <FormLabel >
                            Upload a .csv file with headers "Name" and "Email". <br />

                            Can also add headers with format "2023-05-23:First Preference", "2023-05-23:Second Preference" and "2023-05-23:Third Preference" with the date replaced by the start date of the required rotation

                        </FormLabel>
                        <FileInput source="attachments">
                            <FileField source="src" title="title" />
                        </FileInput>
                    </TabbedForm.Tab>}
            </TabbedForm>
        </Create>)
};

export const NewPreferenceButton = ({ rotationID, traineeRecord }) => {
    // console.log("rotationID", rotationID)
    // console.log("traineeID", traineeRecord)
    var traineeID = traineeRecord?.id?.split("::")[0]
    const record = useRecordContext();
    if (rotationID && !traineeID) {
        traineeID = record.id
        traineeRecord = record
    }
    if (traineeID && !rotationID) {
        rotationID = record.id
    }
    let counter = 0;
    for (let preferenceIndex in traineeRecord.Preferences.items) {
        if (traineeRecord.Preferences.items[preferenceIndex].traineeID == traineeID && traineeRecord.Preferences.items[preferenceIndex].rotationID == rotationID) {
            counter += 1;
        }
    }

    return (<CreateButton label="Add Preferences" resource="preferences" state={{ record: { traineeID: traineeID, rotationID: rotationID } }} disabled={counter >= 4} />)

}

export const TraineeShow = (props) => {
    const traineeRecord = useRecordContext();
    const redirect = useRedirect();
    return (
        <Show actions={<BackButton />} queryOptions={{ meta: { admin: true } }}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <TextField source="name" />
                            <ReferenceField source="cohortID" reference="rotations" label="Training Contract Start Date" link={false} />

                            <ManualOverrideField {...props} label="Manual override for current rotation" />
                        </SimpleShowLayout>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <TextField source="email" />
                            <ReferenceField label="Desired Qualification Area" source="desiredGraduatingSeatID" reference='departments' >
                                <TextField source='name' />
                            </ReferenceField>
                            <Grid container >
                                <Grid item xs={6}>
                                    <SimpleShowLayout sx={{ padding: 0 }}>
                                        <WrapperField label="Corporate Seat Completed">
                                            <BooleanField source="litigationSeatCompleted" />
                                            <EditTickField tick="litigation" />
                                        </WrapperField>
                                    </SimpleShowLayout>
                                </Grid>
                                <Grid item xs={6}>
                                    <SimpleShowLayout sx={{ padding: 0 }}>
                                        <WrapperField label="Banking Seat Completed">
                                            <BooleanField source="bankingSeatCompleted" />
                                            <EditTickField tick="banking" />
                                        </WrapperField>
                                    </SimpleShowLayout>
                                </Grid>
                            </Grid>
                        </SimpleShowLayout>
                    </Grid>
                </Grid >
                <RotationPreferencesList traineeRecord={false} links={(record, resource) => `/departments/${record.id}/show`} />
                <RichTextField source="traineeDiary" label="Comments" />
            </SimpleShowLayout >
        </Show >
    )
};

const TraineeEditToolbar = () => {

    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>)
};

export const TraineeEdit = (props) => {
    const navigate = useNavigate();
    const notify = useNotify();
    const location = useLocation();
    const onSuccess = () => {
        notify("Successfully updated");
        navigate(-1);
    };
    if (location?.state?.tick == "litigation") {
        return (
            <Edit {...props} transform={value => { return value; }} actions={<BackButton />} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
                <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                    <BooleanInput source="litigationSeatCompleted" label="Corporate Seat Completed" />
                </SimpleForm>
            </Edit>)
    }
    if (location?.state?.tick == "banking") {
        return (
            <Edit {...props} transform={value => { return value; }} actions={<BackButton />} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
                <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                    <BooleanInput source="bankingSeatCompleted" />
                </SimpleForm>
            </Edit>)
    }
    return (
        <Edit {...props} transform={value => { return value; }} actions={<BackButton />} mutationMode="pessimistic" queryOptions={{ meta: { admin: true } }} mutationOptions={{ onSuccess, meta: { admin: true } }}>
            <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
                <ReferenceInput source="manualOverrideID" reference='departments' allowEmpty filter={{ departmentsByTypeAndName: { type: "Department" } }} perPage={100}>
                    <SelectInput source="name" label="Manual Override Department" sx={{ width: "200px" }} allowEmpty parse={value => { return value; }} />
                </ReferenceInput>
            </SimpleForm>
        </Edit >)
}

export const TraineeEditUserView = (props) => (
    <Edit {...props} transform={value => { return value; }} redirect={'/'} actions={<BackButton />}>
        <SimpleForm toolbar={<TraineeEditToolbar />} sanitizeEmptyValues={false}>
            <ReferenceInput source="desiredGraduatingSeatID" reference='departments' allowEmpty filter={{ departmentsByTypeAndName: { type: "Department" } }}>
                <SelectInput source="name" label="Desired Qualification Area" sx={{ width: "200px" }} allowEmpty parse={value => { return value; }} />
            </ReferenceInput>
            <RichTextInput source="traineeDiary" label="Comments" />
        </SimpleForm>
    </Edit>
)


export const PostPagination = () => {
    const { page, hasPreviousPage, hasNextPage, setPage, setPerPage, perPage } = useListContext();

    return (
        <Box display="flex" alignItems="stretch" alignContent='space-between' mb={1}>
            <Box display="flex" alignItems="flex-end" mb={1} sx={{ flexGrow: 1 }}>
                {hasPreviousPage &&
                    <Button
                        key="previous"
                        onClick={() => setPage(page - 1)}
                        startIcon={<ChevronLeft />}
                    >
                        Previous
                    </Button>
                }</Box>

            <Box display="flex" alignItems="flex-end" mb={1} sx={{ flexGrow: 1, m: 1 }} justifyContent="flex-end">
                <Box display="flex" alignItems="flex-end" justifyContent="center" sx={{ alignItems: 'center' }}>
                    <Select value={perPage} size="small" onChange={event => { setPerPage(event.target.value) }}>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select> <Typography sx={{ m: 1 }}>per Page</Typography>
                </Box>
                {hasNextPage &&
                    <Button
                        key="next"
                        onClick={() => setPage(page + 1)}
                        startIcon={<ChevronRight />}
                    >
                        Next
                    </Button>
                }

            </Box>
        </Box>
    );
}