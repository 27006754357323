/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSeat = /* GraphQL */ `
  mutation CreateSeat(
    $input: CreateSeatInput!
    $condition: ModelSeatConditionInput
  ) {
    createSeat(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSeat = /* GraphQL */ `
  mutation UpdateSeat(
    $input: UpdateSeatInput!
    $condition: ModelSeatConditionInput
  ) {
    updateSeat(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSeat = /* GraphQL */ `
  mutation DeleteSeat(
    $input: DeleteSeatInput!
    $condition: ModelSeatConditionInput
  ) {
    deleteSeat(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPublishedSeat = /* GraphQL */ `
  mutation CreatePublishedSeat(
    $input: CreatePublishedSeatInput!
    $condition: ModelPublishedSeatConditionInput
  ) {
    createPublishedSeat(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePublishedSeat = /* GraphQL */ `
  mutation UpdatePublishedSeat(
    $input: UpdatePublishedSeatInput!
    $condition: ModelPublishedSeatConditionInput
  ) {
    updatePublishedSeat(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePublishedSeat = /* GraphQL */ `
  mutation DeletePublishedSeat(
    $input: DeletePublishedSeatInput!
    $condition: ModelPublishedSeatConditionInput
  ) {
    deletePublishedSeat(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      preferencePriority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTrainee = /* GraphQL */ `
  mutation CreateTrainee(
    $input: CreateTraineeInput!
    $condition: ModelTraineeConditionInput
  ) {
    createTrainee(input: $input, condition: $condition) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      name
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
        __typename
      }
      manualOverrideID
      manualOverride {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
        __typename
      }
      ownerID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
        __typename
      }
      traineeDiary
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTrainee = /* GraphQL */ `
  mutation UpdateTrainee(
    $input: UpdateTraineeInput!
    $condition: ModelTraineeConditionInput
  ) {
    updateTrainee(input: $input, condition: $condition) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      name
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
        __typename
      }
      manualOverrideID
      manualOverride {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
        __typename
      }
      ownerID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
        __typename
      }
      traineeDiary
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTrainee = /* GraphQL */ `
  mutation DeleteTrainee(
    $input: DeleteTraineeInput!
    $condition: ModelTraineeConditionInput
  ) {
    deleteTrainee(input: $input, condition: $condition) {
      id
      type
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      name
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      email
      cohortID
      cohort {
        id
        type
        startDate
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        cohortsIndex
        averagePreference
        numberOfFirstPreferences
        numberOfSecondPreferences
        numberOfThirdPreferences
        numberOfFourthPreferences
        numberOfFifthPreferences
        numberOfSixthPreferences
        numberOfNoPreferences
        numberOfManualOverrides
        published
        createdAt
        updatedAt
        __typename
      }
      manualOverrideID
      manualOverride {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
        __typename
      }
      ownerID
      litigationSeatCompleted
      bankingSeatCompleted
      desiredGraduatingSeatID
      desiredGraduatingSeat {
        id
        type
        name
        mustBeFilled
        numberOfSeatsAvailable
        numberOfSeatsReservedForSecondYears
        numberOfSeatsReservedForFirstYears
        Preferences {
          items {
            id
            departmentID
            rotationID
            priority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Seats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        PublishedSeats {
          items {
            id
            departmentID
            rotationID
            preferencePriority
            traineeID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        departmentInfo
        litigationDepartment
        bankingDepartment
        createdAt
        updatedAt
        __typename
      }
      traineeDiary
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPreference = /* GraphQL */ `
  mutation CreatePreference(
    $input: CreatePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    createPreference(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      priority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePreference = /* GraphQL */ `
  mutation UpdatePreference(
    $input: UpdatePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    updatePreference(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      priority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePreference = /* GraphQL */ `
  mutation DeletePreference(
    $input: DeletePreferenceInput!
    $condition: ModelPreferenceConditionInput
  ) {
    deletePreference(input: $input, condition: $condition) {
      id
      departmentID
      rotationID
      priority
      traineeID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
      type
      name
      mustBeFilled
      numberOfSeatsAvailable
      numberOfSeatsReservedForSecondYears
      numberOfSeatsReservedForFirstYears
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      departmentInfo
      litigationDepartment
      bankingDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
      type
      name
      mustBeFilled
      numberOfSeatsAvailable
      numberOfSeatsReservedForSecondYears
      numberOfSeatsReservedForFirstYears
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      departmentInfo
      litigationDepartment
      bankingDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      id
      type
      name
      mustBeFilled
      numberOfSeatsAvailable
      numberOfSeatsReservedForSecondYears
      numberOfSeatsReservedForFirstYears
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      departmentInfo
      litigationDepartment
      bankingDepartment
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRotation = /* GraphQL */ `
  mutation CreateRotation(
    $input: CreateRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    createRotation(input: $input, condition: $condition) {
      id
      type
      startDate
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cohortsIndex
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFifthPreferences
      numberOfSixthPreferences
      numberOfNoPreferences
      numberOfManualOverrides
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRotation = /* GraphQL */ `
  mutation UpdateRotation(
    $input: UpdateRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    updateRotation(input: $input, condition: $condition) {
      id
      type
      startDate
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cohortsIndex
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFifthPreferences
      numberOfSixthPreferences
      numberOfNoPreferences
      numberOfManualOverrides
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRotation = /* GraphQL */ `
  mutation DeleteRotation(
    $input: DeleteRotationInput!
    $condition: ModelRotationConditionInput
  ) {
    deleteRotation(input: $input, condition: $condition) {
      id
      type
      startDate
      Seats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Preferences {
        items {
          id
          departmentID
          rotationID
          priority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      PublishedSeats {
        items {
          id
          departmentID
          rotationID
          preferencePriority
          traineeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      cohortsIndex
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFifthPreferences
      numberOfSixthPreferences
      numberOfNoPreferences
      numberOfManualOverrides
      published
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDepartmentStatistic = /* GraphQL */ `
  mutation CreateDepartmentStatistic(
    $input: CreateDepartmentStatisticInput!
    $condition: ModelDepartmentStatisticConditionInput
  ) {
    createDepartmentStatistic(input: $input, condition: $condition) {
      id
      rotationID
      departmentID
      departmentName
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFifthPreferences
      numberOfSixthPreferences
      numberOfFirstPreferencesAssigned
      numberOfSecondPreferencesAssigned
      numberOfThirdPreferencesAssigned
      numberOfFourthPreferencesAssigned
      numberOfFifthPreferencesAssigned
      numberOfSixthPreferencesAssigned
      numberOfNoPreferencesAssigned
      numberOfManualOverrides
      numberOfSeatsAssigned
      numberOfSeatsAvailable
      numberOfFirstYearsAssigned
      numberOfSecondYearsAssigned
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDepartmentStatistic = /* GraphQL */ `
  mutation UpdateDepartmentStatistic(
    $input: UpdateDepartmentStatisticInput!
    $condition: ModelDepartmentStatisticConditionInput
  ) {
    updateDepartmentStatistic(input: $input, condition: $condition) {
      id
      rotationID
      departmentID
      departmentName
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFifthPreferences
      numberOfSixthPreferences
      numberOfFirstPreferencesAssigned
      numberOfSecondPreferencesAssigned
      numberOfThirdPreferencesAssigned
      numberOfFourthPreferencesAssigned
      numberOfFifthPreferencesAssigned
      numberOfSixthPreferencesAssigned
      numberOfNoPreferencesAssigned
      numberOfManualOverrides
      numberOfSeatsAssigned
      numberOfSeatsAvailable
      numberOfFirstYearsAssigned
      numberOfSecondYearsAssigned
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDepartmentStatistic = /* GraphQL */ `
  mutation DeleteDepartmentStatistic(
    $input: DeleteDepartmentStatisticInput!
    $condition: ModelDepartmentStatisticConditionInput
  ) {
    deleteDepartmentStatistic(input: $input, condition: $condition) {
      id
      rotationID
      departmentID
      departmentName
      averagePreference
      numberOfFirstPreferences
      numberOfSecondPreferences
      numberOfThirdPreferences
      numberOfFourthPreferences
      numberOfFifthPreferences
      numberOfSixthPreferences
      numberOfFirstPreferencesAssigned
      numberOfSecondPreferencesAssigned
      numberOfThirdPreferencesAssigned
      numberOfFourthPreferencesAssigned
      numberOfFifthPreferencesAssigned
      numberOfSixthPreferencesAssigned
      numberOfNoPreferencesAssigned
      numberOfManualOverrides
      numberOfSeatsAssigned
      numberOfSeatsAvailable
      numberOfFirstYearsAssigned
      numberOfSecondYearsAssigned
      createdAt
      updatedAt
      __typename
    }
  }
`;
